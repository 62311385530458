import React from "react";
import { Outlet } from "react-router";
import AppVersion from "../AppVersion";

const Layout = () => {
  return (
    <>
      <Outlet />
      <AppVersion />
    </>
  );
};

export default Layout;
